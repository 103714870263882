import React from "react";

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <p>A website by Ersoy Efe Uruk | All rights reserved © 2020</p>
      </div>
    </footer>
  );
}
